import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import PropTypes from "prop-types";

const AuthenticationModal = loadable(() => import("./AuthenticationModal"));

const AuthenticationModalLoader = (props) => {
	const [firstRender, setFirstRender] = useState(false);

	const { show } = props;
	useEffect(() => {
		if (show) {
			setFirstRender(true);
		}
	}, [show]);
	return <>{firstRender && <AuthenticationModal {...props} />}</>;
};

AuthenticationModalLoader.propTypes = {
	apiBaseUrl: PropTypes.string,
	show: PropTypes.bool,
	handleModalClose: PropTypes.func,
	getXCSRFToken: PropTypes.func,
	isStaticBuild: PropTypes.bool,
};
AuthenticationModalLoader.defaultProps = {
	apiBaseUrl: "",
	show: false,
	handleModalClose: () => {},
	getXCSRFToken: () => {},
	isStaticBuild: true,
};

export default AuthenticationModalLoader;
