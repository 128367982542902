import styled from "styled-components";
import {
	TABLET_BP,
	DESKTOP_SM_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import {
	FONT_WT_MEDIUM,
	FONT_WT_REGULAR,
} from "@website-builder/utilities/constants/style-constants/fonts.js";
import {
	COLOR_TEXT_PRIMARY_B2C,
	COLOR_FACEBOOK_BLUE,
	COLOR_LINKEDIN_BLUE,
	COLOR_GOOGLE_ORANGE,
	COLOR_ERROR_RED,
	COLOR_RED,
	COLOR_GREEN,
} from "@website-builder/utilities/constants/style-constants/colors.js";

export const AuthModalContainer = styled.div`
	.modal {
		padding: 0;
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		.modal {
			top: 0;
			left: 0;
			transform: unset;
			width: 100%;
			height: 100vh;
			border-radius: 0;
		}
	}
	@media only screen and (max-width: ${TABLET_BP}px) {
		.modal {
			overflow-y: scroll;
		}
	}
`;

export const AuthModalWrapper = styled.div`
	width: 720px;
	padding: 20px 30px 30px;
	.ff-haffer {
		font-family: var(--ff-haffer-xh);
	}
	.other-login-btn {
		line-height: 44px;
		font-weight: 500;
		color: white;
		font-size: 18px;
	}
	.margin-v1 {
		margin: 1rem 0;
	}
	h2,
	.log-in-title {
		font-weight: ${FONT_WT_MEDIUM};
		text-align: left;
		color: ${COLOR_TEXT_PRIMARY_B2C};
		font-size: 30px;
		margin: 25px 0;
		line-height: 1.29;
		font-family: "Haffer XH";
	}
	p {
		font-size: 16px;
		font-weight: 300;
		line-height: 27px;
		text-align: left;
		color: ${COLOR_TEXT_PRIMARY_B2C};
	}
	.formControl {
		max-width: 650px;
		margin-bottom: 10px;
	}
	.formErrorWrapper {
		margin-bottom: 5px;
		color: ${COLOR_ERROR_RED};
	}
	.checkbox {
		margin-top: 10px;
		margin-bottom: 10px;
		label {
			min-height: 20px;
			padding-left: 20px;
			margin-bottom: 0;
			font-weight: ${FONT_WT_REGULAR};
			cursor: pointer;
		}
	}
	button {
		width: 270px;
	}
	.socialButtonContainer {
		display: inline-block;
		margin-right: 20px;
	}
	.socialLoginButton {
		height: 44px;
		width: 170px;
		display: inline-block;
		padding: 0 16px 0 60px;
		line-height: 44px;
		font-weight: ${FONT_WT_MEDIUM};
		color: white;
		text-decoration: none;
		font-size: 18px;
		position: relative;
		text-decoration: none;
		border-radius: 3px;
		overflow: hidden;
		.socialLogo {
			position: absolute;
			top: 0;
			left: 0;
		}
		&.facebook {
			background: ${COLOR_FACEBOOK_BLUE};
			img {
				height: 34px;
				margin-top: 2px;
			}
		}
		&.google {
			background: ${COLOR_GOOGLE_ORANGE};
			img {
				height: 38px;
				margin: 3px 0 0 4px;
			}
		}
		&.linkedin {
			background: ${COLOR_LINKEDIN_BLUE};
			img {
				height: 38px;
				margin: 2px 0 0 5px;
			}
		}
	}
	.authModeSwitchContainer {
		margin-top: 20px;
		.authmodeSwitch {
			color: #2d87b0;
			cursor: pointer;
			margin: 0 5px;
			border: none;
			padding: 0;
			background: transparent;
			text-align: left;
			font-size: 1rem;
			max-width: fit-content;
		}
		.forgotPasswordWrapper {
			display: inline-block;
			float: right;
			a {
				text-decoration: none;
			}
			p {
				color: #2d87b0;
				font-weight: 400;
			}
		}
	}

	.password-policy {
		margin: 10px 0 0 10px;
		label {
			display: block;
			margin: 8px 0;
			i.fa {
				margin-right: 5px;
			}
		}
		.text-danger {
			color: ${COLOR_RED};
		}
		.text-success {
			color: ${COLOR_GREEN};
		}
	}

	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		width: 100%;
	}
	@media only screen and (max-width: ${TABLET_BP - 1}px) {
		padding: 0 10px;
		.socialButtonContainer {
			display: block;
			margin-right: 0;
			margin-bottom: 10px;
			.socialLoginButton {
				width: 100%;
			}
		}
		button {
			width: 100%;
		}
		.authModeSwitchContainer {
			.forgotPasswordWrapper {
				display: block;
				float: none;
				line-height: 27px;
			}
		}
	}
`;
