import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { AuthModalWrapper, AuthModalContainer } from "./styles";
import {
	CloudinaryImage,
	Button,
	Text,
	Modal,
	Input,
} from "@website-builder/ui/shared/elements";
import {
	addToDataLayer,
	getResult,
	isEmailValid,
} from "@website-builder/utilities/utils/utils.js";
import {
	EMAIL_INVALID,
	EMAIL_REQUIRED,
	LOGIN_FORM_ERROR,
	PASSWORD_REQUIRED,
} from "@website-builder/utilities/constants/messages.js";

import ReCAPTCHA from "react-google-recaptcha";

const DEFUALT_ERROR_OBJECT = { error: false, message: "" };
const LOGIN_AUTH_MODE = "LOGIN";
const SIGNUP_AUTH_MODE = "SIGNUP";
const PASSWORD_POLICY = {
	MIN_CHAR: {
		pattern: /(?=^.{8,}$)/g,
		valid: false,
		id: "min-char",
		message: "Please enter at least 8 characters.",
	},
	MAX_CHAR: {
		id: "max-char",
		pattern: /^.{0,64}$/g,
		valid: false,
		message: "Please enter less than 64 characters.",
	},
	LOWER_CASE: {
		id: "lower-case",
		pattern: /^(?=.*[a-z]).*$/g,
		valid: false,
		message: "Password must contain at least one lowercase letter.",
	},
	UPPER_CASE: {
		id: "upper-case",
		pattern: /^(?=.*[A-Z]).*$/g,
		valid: false,
		message: "Password must contain at least one uppercase letter.",
	},
	NUMBER_CHAR: {
		id: "number-char",
		pattern: /^(?=.*[0-9]).*$/g,
		valid: false,
		message: "Password must contain at least one number.",
	},
	SPECIAL_CHAR: {
		id: "special-char",
		pattern: /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).*$/g,
		valid: false,
		message: "Password must contain at least one special character.",
	},
	EMAIL_ATTRIBUTE: {
		id: "email_attr",
		valid: false,
		message: "Password must not contain email text.",
	},
};

const AuthenticationModal = (props, context) => {
	const {
		apiBaseUrl,
		show,
		handleModalClose,
		getXCSRFToken,
		globalHostName,
		isStaticBuild,
	} = props;

	const [authMode, setAuthMode] = useState(LOGIN_AUTH_MODE);
	const [siteKey, setSiteKey] = useState(null);
	const [recaptchaEnabled, setRecaptchaEnabled] = useState(false);
	const recaptchaRef = React.createRef();
	const [form, setForm] = useState({
		email: "",
		password: "",
		rememberPassword: false,
	});
	const [errorObjects, setErrorObjects] = useState({
		email: DEFUALT_ERROR_OBJECT,
		loginPassword: DEFUALT_ERROR_OBJECT,
		signUpPassowrd: { ...PASSWORD_POLICY },
		form: DEFUALT_ERROR_OBJECT,
	});
	const crossIcon = String.fromCodePoint(10060);
	const correctIcon = String.fromCodePoint(10004);

	useEffect(() => {
		setAuthMode(LOGIN_AUTH_MODE);
	}, [show]);

	const handleFormChange = (field, value) => {
		setForm({
			...form,
			[field]: value,
		});
	};

	const setFieldError = (field, errorObj) =>
		setErrorObjects({
			...errorObjects,
			[field]: { ...errorObj },
		});

	const resetFieldError = (field) =>
		setErrorObjects({
			...errorObjects,
			[field]: { ...DEFUALT_ERROR_OBJECT },
		});

	useEffect(() => {
		const baseUrl =
			props.apiBaseUrl ||
			`${window.location.protocol}//${window.location.host}`;

		const fetchSiteKey = async () => {
			await fetch(`${baseUrl}/curriculum/api/service-config/`)
				.then((res) => res.json())
				.then((res) => {
					setRecaptchaEnabled(res.recaptcha_enabled);
					setSiteKey(res.recaptcha_site_key);
				})
				.catch((err) => {
					console.error(
						"AuthenticationModal::fetchSiteKey  error in getting recaptcha_site_key",
						err,
					);
				});
		};

		isStaticBuild && fetchSiteKey();
	}, []);

	const handleFormSubmit = (e) => {
		e.preventDefault();
		resetFieldError("form");
		const isFormValid = validateForm();

		if (isFormValid) {
			validateReCaptcha();
		}
	};

	const postLoginApi = (responseToken) => {
		getXCSRFToken(apiBaseUrl)
			.then((csrfToken) => {
				var headers = new Headers();
				headers.append("Accept", "application/json, text/plain, */*");
				headers.append("X-CSRFToken", csrfToken);
				headers.append("X-Requested-With", "XMLHttpRequest");

				const authApiEndpoint = getAuthApiEndPoint();
				const body = getAuthApiBody(responseToken);

				fetch(authApiEndpoint, {
					body,
					headers,
					method: "post",
					credentials: "include",
				})
					.then((res) => {
						return getResult(res);
					})
					.then((response) => {
						const { location = "" } = response;

						if (location.includes("confirm-email")) {
							addToDataLayer({
								event: "signup",
								email: form.email,
							});
							window.location.href = globalHostName + "/confirm-email";
						} else {
							const userAPI = `${apiBaseUrl}/user/`;
							fetch(userAPI, {
								credentials: "include",
							})
								.then((res) => {
									return getResult(res);
								})
								.then((response) => {
									// Trigger Login Analytics Events
									addToDataLayer({
										event: "login",
										user_uuid: response?.user_uuid,
										userId: response?.id,
									});
									const { redirect_link = "" } = response;
									if (redirect_link) {
										window.location.href = globalHostName + redirect_link;
									} else {
										console.error(
											"AuthenticationModal::postLoginApi no redirect link passed in user response",
											response,
										);
									}
								})
								.catch((err) => {
									console.error(
										"AuthenticationModal::postLoginApi unable to get user details to redirect",
										err.response,
									);
								});
						}
					})
					.catch((err) => {
						console.error(
							"AuthenticationModal::postLoginApi  error while loging/signup",
							err.response,
						);
						resetCaptcha();
						setFormErrorMessage(err.response);
					});
			})
			.catch((err) => {
				console.error(
					"AuthenticationModal::postLoginApi  error while getting XCSRFToken",
					err.response,
				);
				resetCaptcha();
			});
	};

	const setFormErrorMessage = async (err) => {
		const { form } = await err.json();
		let errorMsg = LOGIN_FORM_ERROR;
		if (form) {
			if (form.errors.length) {
				errorMsg = form.errors[0];
			}
			if (form.fields) {
				const { login = null, password = null, password1 = null } = form.fields;

				if (login && login.errors.length) {
					errorMsg = login.errors[0];
				}

				if (password && password.errors.length) {
					errorMsg = `Password ${password.errors[0]}`;
				}

				if (password1 && password1.errors.length) {
					errorMsg = `Password ${password1.errors[0]}`;
				}
			}
		}
		setFieldError("form", {
			error: true,
			message: errorMsg,
		});
	};

	const validateForm = () => {
		let isFormValid = true;
		const errorObjUpdate = {};
		const { email, password } = form;
		const trimmedEmail = email.trim(),
			trimmedPassword = password.trim();

		if (!trimmedEmail) {
			errorObjUpdate.email = { error: true, message: EMAIL_REQUIRED };
			isFormValid = false;
		} else {
			if (!isEmailValid(trimmedEmail)) {
				isFormValid = false;
				errorObjUpdate.email = {
					error: true,
					message: EMAIL_INVALID,
				};
			}
		}

		// for login
		if (
			(!trimmedPassword || trimmedPassword.length < 8) &&
			authMode === LOGIN_AUTH_MODE
		) {
			isFormValid = false;
			errorObjUpdate.loginPassword = {
				error: true,
				message: PASSWORD_REQUIRED,
			};
		}

		// for signup
		if (authMode === SIGNUP_AUTH_MODE && !isValidPassword(trimmedPassword)) {
			isFormValid = false;
		}

		setErrorObjects({ ...errorObjects, ...errorObjUpdate });
		return isFormValid;
	};

	const toggleAuthMode = () => {
		authMode === LOGIN_AUTH_MODE
			? setAuthMode(SIGNUP_AUTH_MODE)
			: setAuthMode(LOGIN_AUTH_MODE);
	};

	const getLoginModeString = () =>
		authMode === LOGIN_AUTH_MODE ? "Log In" : "Sign up";

	const getAuthApiEndPoint = () =>
		authMode === LOGIN_AUTH_MODE
			? `${apiBaseUrl}/accounts/login/`
			: `${apiBaseUrl}/accounts/signup/`;

	const getAuthApiBody = (responseToken) => {
		let body = new FormData();

		if (authMode === LOGIN_AUTH_MODE) {
			body.append("login", form.email);
			body.append("password", form.password);
			body.append("remember", form.rememberPassword);
		}

		if (authMode === SIGNUP_AUTH_MODE) {
			body.append("email", form.email);
			body.append("password1", form.password);
		}

		body.append("recaptchaToken", responseToken);
		return body;
	};

	const verifyCallback = (responseToken) => {
		if (responseToken) {
			postLoginApi(responseToken);
		} else {
			console.error(
				"AuthenticationModal::verifyCallback did not get any response token",
			);
		}
	};

	const validateReCaptcha = () => {
		if (!recaptchaEnabled) {
			postLoginApi(null);
		} else {
			if (recaptchaRef && recaptchaRef.current) {
				recaptchaRef.current.execute();
			} else {
				console.error(
					"AuthenticationModal::validateReCaptcha Not able load recaptcha",
				);
			}
		}
	};

	const resetCaptcha = () => {
		if (recaptchaRef && recaptchaRef.current) {
			recaptchaRef.current.reset();
		} else {
			console.error(
				"AuthenticationModal::resetCaptcha Not able load recaptcha",
			);
		}
	};

	const isValidPassword = (password = "") => {
		let valid = true;
		const policies = { ...errorObjects.signUpPassowrd };
		const policyTypes = Object.keys(policies);
		const updatedPolicy = policyTypes.reduce((acc, type) => {
			const policy = { ...policies[type] };
			if (type === "EMAIL_ATTRIBUTE") {
				const userName = getUserNameFromEmail();
				if (password && password.toLowerCase().includes(userName)) {
					policy.valid = false;
					valid = false;
				} else {
					policy.valid = true;
				}
			} else {
				if (!password || !password.match(policy.pattern)) {
					policy.valid = false;
					valid = false;
				} else {
					policy.valid = true;
				}
			}
			return { ...acc, ...{ [type]: policy } };
		}, {});
		setErrorObjects({ ...errorObjects, ...{ signUpPassowrd: updatedPolicy } });
		return valid;
	};

	const getUserNameFromEmail = () => {
		const email = form.email.split("@");
		return email.length ? email[0].toLowerCase() : null;
	};

	const renderPasswordPolicy = () => {
		const policies = { ...errorObjects.signUpPassowrd };
		const policyTypes = Object.keys(policies);
		return policyTypes.map((type) => {
			const policy = policies[type];
			return (
				<label
					className={`policy-text ff-haffer ${
						policy.valid ? "text-success" : "text-danger"
					}`}
				>
					<pre>{`${policy.valid ? correctIcon : crossIcon}  ${
						policy.message
					}`}</pre>
				</label>
			);
		});
	};

	return (
		<AuthModalContainer>
			<Modal
				show={show}
				onClose={handleModalClose}
				disableBackdropClick={true}
				className="modal"
				id="authentication-login-modal"
			>
				<AuthModalWrapper>
					<div className="ff-haffer log-in-title"> {getLoginModeString()}</div>
					<Text className="margin-v1">{getLoginModeString()} with</Text>
					<div className="row-block">
						{authMode === LOGIN_AUTH_MODE && (
							<div className="socialButtonContainer">
								<a
									className="socialLoginButton facebook"
									href="/fb-login-update/"
								>
									<CloudinaryImage
										className="socialLogo"
										url="https://res.cloudinary.com/springboard-images/image/upload/v1592207292/homepage-assets/navigation-bar/facebook.svg"
										lazyload
										dynamic
									/>
									<span className="other-login-btn ff-haffer"> Facebook</span>
								</a>
							</div>
						)}
						<div className="socialButtonContainer">
							<a
								className="socialLoginButton google"
								href={`${apiBaseUrl}/accounts/google/login/?process=login&amp;next=${encodeURIComponent(
									globalHostName,
								)}`}
							>
								<CloudinaryImage
									className="socialLogo"
									url="https://res.cloudinary.com/springboard-images/image/upload/v1592207292/homepage-assets/navigation-bar/google.svg"
									lazyload
									dynamic
								/>
								<span className="other-login-btn ff-haffer"> Google</span>
							</a>
						</div>
						<div className="socialButtonContainer">
							<a
								className="socialLoginButton linkedin"
								href={`${apiBaseUrl}/accounts/linkedin_oauth2/login/?process=login&amp;next=${encodeURIComponent(
									globalHostName,
								)}`}
							>
								<CloudinaryImage
									className="socialLogo"
									url="https://res.cloudinary.com/springboard-images/image/upload/v1592207292/homepage-assets/navigation-bar/linkedin.svg"
									lazyload
									dynamic
								/>
								<span className="other-login-btn ff-haffer"> Linkedin</span>
							</a>
						</div>
					</div>
					<Text className="margin-v1">
						Or {getLoginModeString()} with your email
					</Text>
					{siteKey && (
						<ReCAPTCHA
							ref={recaptchaRef}
							size="invisible"
							sitekey={siteKey}
							onChange={(e) => verifyCallback(e)}
						/>
					)}
					<form onSubmit={handleFormSubmit}>
						<div className="formErrorWrapper">
							{errorObjects.form.error && (
								<span>{errorObjects.form.message}</span>
							)}
						</div>
						<div className="formControl">
							<Input
								type="email"
								value={form.email}
								errorObj={errorObjects.email}
								placeholder="Enter your email"
								onFocus={() => resetFieldError("email")}
								onChange={(e) => handleFormChange("email", e.target.value)}
							/>
						</div>
						{authMode === LOGIN_AUTH_MODE && (
							<div className="formControl">
								<Input
									type="password"
									value={form.password}
									errorObj={errorObjects.loginPassword}
									placeholder="Enter your password"
									onFocus={() => resetFieldError("loginPassword")}
									onChange={(e) => handleFormChange("password", e.target.value)}
									onKeyUp={(e) => isValidPassword(e.target.value)}
								/>
							</div>
						)}
						{authMode === SIGNUP_AUTH_MODE && (
							<div className="formControl">
								<Input
									type="password"
									value={form.password}
									placeholder="Enter your password"
									onChange={(e) => handleFormChange("password", e.target.value)}
									onKeyUp={(e) => isValidPassword(e.target.value)}
								/>
								<div className="password-policy">{renderPasswordPolicy()}</div>
							</div>
						)}
						<div className="checkbox">
							<input
								type="checkbox"
								id="rememberPassword"
								checked={form.rememberPassword}
								onChange={(e) =>
									handleFormChange("rememberPassword", e.target.checked)
								}
							/>
							<label className="ff-haffer" htmlFor="rememberPassword">
								Remember Me
							</label>
						</div>
						<div>
							<Button
								btnType="submit"
								buttonText={
									authMode === LOGIN_AUTH_MODE ? "Login" : "Create Account"
								}
							/>
						</div>
					</form>
					{authMode === LOGIN_AUTH_MODE && (
						<div className="authModeSwitchContainer">
							<Text style={{ display: "inline-block" }}> Need an account?</Text>
							<button
								className="authmodeSwitch ff-haffer"
								onClick={toggleAuthMode}
							>
								Sign up
							</button>
							<div className="forgotPasswordWrapper">
								<a className="clickable" href={`/password-reset/`}>
									<Text>Forgot password?</Text>
								</a>
							</div>
						</div>
					)}
					{authMode === SIGNUP_AUTH_MODE && (
						<div className="authModeSwitchContainer">
							<Text style={{ display: "inline-block" }}>
								Already have an account?
							</Text>
							<span
								className="authmodeSwitch  ff-haffer"
								onClick={toggleAuthMode}
							>
								Sign in
							</span>
						</div>
					)}
				</AuthModalWrapper>
			</Modal>
		</AuthModalContainer>
	);
};

export default AuthenticationModal;
